<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import {asyncRoutes, resetRouter} from "@/router";
import {computedAsyncRoutes} from "@/store/modules/user";

export default {
  name: 'App',
  beforeCreate() {
    console.log(asyncRoutes)
    console.log(this.$store.state.user.info.routes)
    console.log(computedAsyncRoutes(asyncRoutes,this.$store.state.user.info.routes))
    this.$store.dispatch('user/changeResultAsyncRoutes',
        computedAsyncRoutes(asyncRoutes,this.$store.state.user.info.routes)
    )
    console.log(this.$store.state.user.resultAsyncRoutes)
    this.$router.addRoutes(this.$store.state.user.resultAsyncRoutes)
    console.log(this.$router)
  }
}
</script>