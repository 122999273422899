import h from "./http/http";

const http = h.http
const get = h.get
const post = h.post

const paperLibrary = {
    // 创建批次
    postBatchCrate(params) {
        return post("/school/batch/create", params);
    },
    // 获取送审规则
    getSendingRuleList() {
        return get("/school/get_sendingRuleList");
    },
    // 获取所有批次预览
    getAllBatch() {
        return get("/school/getAllBatch");
    },
    //获取层级学科
    getAllSubject() {
        return get("/school/get_AllSubject")
    },
    //获取单篇论文基本信息
    getPaperBasicInfo(params) {
        return get("/paper/get_paperBasicInfo", params)
    },
    // 获取原文库论文
    getOriginalPaperPreviews(params) {
        return get("/paper/get_originalPaperPreviews", params)
    },
    // 上传学生名单
    postUploadStudent(params) {
        return post('/school/uploadStudent', params);
    },
    getAllInstitution(params){
        return get('/school/getAllInstitution', params)
    },
    getSchoolBatch(params){
        return get('/school/getSchoolBatch', params)
    },
    getSchoolPaperList(params){
        return get('/school/getSchoolPaperList', params)
    },
    // 院校端提交送审
    getSubmit(params) {
        return get('/paper/school/submit', params);
    },
    // zxr 下载附件
    downloadFile(params) {
        // return get('/paper/downloadPaperAnnex',params);
        return http.get('/paper/downloadPaperAnnex', {
            // paperId: params.paperId,
            params: { paperId: params.paperId },
            responseType: 'blob'
        });
    },
    // 删除论文
    deletePapers(params) {
        return post('/paper/deletePapers', params);
    },
    // 撤回论文
    withdrawPapers(params) {
        return post('/paper/withdrawPapers', params);
    },
    // 敏感词检测
    checkSensitiveWords(params) {
        return post('/paper/checkSensitiveWords', params);
    },
    // 下载敏感词检测结果
    // API 请求方法：传递 zipFilePath 作为查询参数
    // 下载zip
    getZip(params) {
        return http.get('/paper/downloadZipFile', {
            params: params,
            responseType: 'blob'
        });
    },
    // //提取论文关键词
    // extractKeywords(params) {
    //     return post('/paper/extractKeywords', params);
    // },
    //提取论文关键词
    extractKeywords(params) {
        return http.get('/paper/extractKeywords', {
            params: params,
            responseType: 'blob'
        });
    },
    //下载论文原件
    downloadPaperFile(params) {
        // return get('/paper/downloadPaperAnnex',params);
        return http.get('/paper/downloadPaperFile', {
            // paperId: params.paperId,
            params: { paperId: params.paperId },
            responseType: 'blob'
        });
    },
    //获取敏感词检测进度
    getProgress() {
        return get('/paper/getProgress');
    },
    // 加送
    addPaper(params) {
        return post("/paper/addPaper", params);
    },
    //复审
    reviewPaper(params) {
        return post("paper/reviewPaper", params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
}

export default paperLibrary